$offset: 187;
$duration: 1.4s;
.loading-spinner {
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.spinner {
  animation: rotator $duration linear infinite;
  display: block;
  margin: auto;
  top: 50%;
  left: 50%;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #5fbe43;
  }
  25% {
    stroke: #4f888d;
  }
  50% {
    stroke: #276fab;
  }
  75% {
    stroke: #4f888d;
  }
  100% {
    stroke: #5fbe43;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
